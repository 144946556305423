import http from "../../http-commons"

const getAllProductUsers= (pageMO) => {
   return http.post("/getAllProductUser", pageMO);
}

const createProductUser = (data) => {
    return http.post("/saveProductUser", data);
}

const updateProductUser = (data) => {
    return http.put("/updateProductUser", data);
}

const genEmailVerificatoinCode = (data) => {
    return http.put("/genEmailVerificatoinCode", data);
}


const verifyEmailVerificationCode = (data) => {
    return http.put("/verifyEmail", data);
}

const deleteProductUser = (data) => {
    return http.put("/deleteProductUser", data);
}


 const getAllPlan= (pageMO) => {
     return http.post("/getAllPlan", pageMO);
  }
 
 const savePlan = (data) => {
     return http.post("/savePlan", data);
 }
 
 const updatePlan = (data) => {
     return http.put("/updatePlan", data);
 }
 
 const deletePlan = (data) => {
     return http.put("/deletePlan", data);
 }


 const getAllSubscription= (pageMO) => {
    return http.post("/getAllSubscription", pageMO);
 }

const saveSubscription = (data) => {
    return http.post("/saveSubscription", data);
}

const updateSubscription = (data) => {
    return http.put("/updateSubscription", data);
}

const deleteSubscription = (data) => {
    return http.put("/deleteSubscription", data);
}

const getAllPlanWOPagination = () => {
    return http.get("/getAllPlanWOPagination");
}

const getAllProductUserWOPagination = () => {
    return http.get("/getAllProductUserWOPagination");
}

export default {
    getAllProductUsers,
    createProductUser,
    updateProductUser,
    deleteProductUser,
    getAllPlan,
    savePlan,
    updatePlan,
    deletePlan,
    genEmailVerificatoinCode,
    verifyEmailVerificationCode,
    deleteSubscription,
    updateSubscription,
    saveSubscription,
    getAllSubscription,
    getAllProductUserWOPagination,
    getAllPlanWOPagination
}