import { Alert, Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import ManageProductService from "../service/ManageProductService";
import Autocomplete from "../common/Autocomplete";
import ReactDatePicker from "react-datepicker";
import { format } from 'date-fns-tz';

const AddSubscription = ({ updateParent }) => {
    const [submitted, setSubmitted] = useState(false);
    const [message, setMessage] = useState("");
    const [alertVariant, setAlertVariant] = useState("");
    const [alertHeading, setAlertHeading] = useState("");
    const [show, setShow] = useState(false);
    const [startDate, setStartDate] = useState();
    const initialSubscription = {
        id: '',
        planMO: {
            id: '',
            type: ''
        },
        productUserMO: {
            id: '',
            customerId: ''
        },
        startDate:"",
    }
    const [subscription, setSubscription] = useState(initialSubscription);
    const [productUsers, setProductUsers] = useState([]);
    const initialProductUser = {
        id: '',
        customerId: ''
    }
    const [productUser, setProductUser] = useState(initialProductUser);

    const [plans, setPlans] = useState([]);
    const initialPlan = {
        id: '',
        type: ''
    }
    const [plan, setPlan] = useState(initialPlan);

    const [subscriptionError, setSubscriptionError] = useState({
        productUserError: '',
        planError: '',
        startDateError: ''
    });

    useEffect(() => {
        retrieveProductUsers();
        retrievePlans();
    }, []);

    const retrieveProductUsers = () => {
        ManageProductService.getAllProductUserWOPagination()
            .then(response => {
                setProductUsers(response.data);
                setSubmitted(false);
                console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    };


    const retrievePlans = () => {
        ManageProductService.getAllPlanWOPagination()
            .then(response => {
                setPlans(response.data);
                setSubmitted(false);
                console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    };

    const handleValidation = () => {
        if (!productUser?.id) {
            setSubscriptionError({ ...subscriptionError, 'productUserError': 'Please select product user' });
            return false;
        } else if (!plan?.id) {
            setSubscriptionError({ ...subscriptionError, 'productUserError': '', 'planError': 'Please select plan' });
            return false;
        } else if (!startDate) {
            setSubscriptionError({ ...subscriptionError, 'productUserError': '', 'planError': '', 'startDateError': 'Please enter start date' });
            return false;
        }
        setSubscriptionError({ ...subscriptionError, 'productUserError': '', 'planError': '', 'startDateError': '' });
        return true;
    }

    const saveSubcription = () => {
        setSubmitted(true);
        if (handleValidation()) {
            subscription.productUserMO = productUser;
            subscription.planMO = plan;
            subscription.startDate = format(startDate, "dd.MM.yyyy", { timeZone: "Asia/Kolkata" }),
                ManageProductService.saveSubscription(subscription)
                    .then(response => {
                        updateParent()
                        setShow(true);
                        setTimeout(() => {
                            setShow(false);
                        }, 3000);
                        setAlertHeading("Success!!");
                        setAlertVariant("success");
                        setMessage("Successfully added");
                        setSubmitted(false);
                        initializeObject();
                    })
                    .catch(e => {
                        setShow(true);
                        setMessage(e.response.data);
                        setAlertHeading("Error!");
                        setAlertVariant("danger");
                        setSubmitted(false);
                    })
        }
    }

    const initializeObject = () => {
        setSubscription(initialSubscription);
        setProductUser(initialProductUser);
        setPlan(initialPlan);
        setStartDate('');
    }
    return (
        <div className="p-5">
            <div className="col-md-12 float-right">
                {show &&
                    <div className="col-md-12 float-left">
                        <Alert style={{ width: 'auto' }} variant={alertVariant} onClose={() => setShow(false)} dismissible>
                            <Alert.Heading>{alertHeading}</Alert.Heading>
                            <p>{message}</p>
                        </Alert></div>}
                <div className="adminFormLeft">
                    <lable className='required'>Customer Id</lable> <span className='errorMsg'>  {subscriptionError.productUserError}</span>
                    <Autocomplete items={productUsers}
                        searchField={'customerId'}
                        renderItem={(item) =>
                            <>
                                {item.customerId} - {item.name}
                            </>

                        }
                        onChange={(event, value) => { setProductUser({ ...productUser, ['customerId']: value }) }}
                        onSelect={(value, item) => {
                            setProductUser(item ? item : undefined)
                        }}
                        value={productUser?.customerId} />
                </div>

                <div className="adminFormRight">
                    <lable className='required'>Plan</lable> <span className='errorMsg'>  {subscriptionError.planError}</span>
                    <Autocomplete items={plans}
                        searchField={'type'}
                        renderItem={(item) =>
                            <>
                                {item.type}
                            </>

                        }
                        onChange={(event, value) => { setPlan({ ...plan, ['type']: value }) }}
                        onSelect={(value, item) => {
                            setPlan(item ? item : undefined)
                        }}
                        value={plan?.type} />
                </div>

                <div className="adminFormLeft mt-2">
                    <lable className='required'>Start Date</lable><span className='errorMsg'>  {subscriptionError.startDateError}</span>
                    <ReactDatePicker autoComplete="off" selected={startDate} name="startDate" id="startDate" className="form-control" onChange={(date) => setStartDate(date)} dateFormat="dd/MM/yyyy" />
                </div>

                <div className="col-md-12 float-right mt-3 mb-2">
                    <button color="primary"
                        variant="contained"
                        className="float-right add-btn"
                        onClick={(e) => saveSubcription(e)} disabled={submitted}>
                        {submitted ? 'Saving...' : 'Save'}
                    </button>
                </div></div>
        </div>
    )
}

export default AddSubscription;