import { Dialog, DialogContent, DialogContentText } from '@mui/material';
import * as React from 'react';
import { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";



export default function Loader({ openDialog }) {

    const [open, setOpen] = useState(false);
    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        setOpen(openDialog);
    }, [openDialog]);

    const handleOk = () => {
        setOpen(false);
        // disable successfun since alert box doent not required to do any save actions.
    };



    const [buttonFocus, setButtonFocus] = useState({ backgroundColor: 'grey' });
    const setOnFocus = () => {
        setButtonFocus({ backgroundColor: 'yellow' });
    }

    return (
        <div>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >

                <DialogContent>
                    <DialogContentText style={{ color: '#4CAF50' }} className="warn-content" id="alert-dialog-description">
                        <Spinner style={{ marginLeft:'30%' }} animation="border"></Spinner>
                    </DialogContentText>
                    <DialogContentText style={{ color: '#343A40' }}>
                        <label >fetching data...</label>
                    </DialogContentText>
                </DialogContent>

            </Dialog>
        </div>
    );

}