import { useEffect, useRef, useState } from "react";
import AddProductUser from "./AddProductUser";
import { Alert, Modal } from "react-bootstrap";
import { CSSTransition } from "react-transition-group";
import { Link } from "react-router-dom";
import Constants from "../../Constants";
import FMTable from "../../components/common/FMTable";
import Loader from "../../components/common/Loader";
import ManageProductService from "../service/ManageProductService";
import EditProductUser from "./EditProductUser";
import { Download } from "@mui/icons-material";
import ProductUserEmailVerification from "./ProductUserEmailVerification";
import { Check2Circle } from 'react-bootstrap-icons';

const ProductUserList = () => {

    // general variables//
    const [message, setMessage] = useState("");
    const [alertVariant, setAlertVariant] = useState("");
    const [alertHeading, setAlertHeading] = useState("");
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(true);
    const [showEmailVerification, setShowEmailVerification] = useState(false);
    // general variables//

    const [productUsers, setProductUsers] = useState([]);
    const [productUserEmailVer, setProductUserEmailVer] = useState();

    const [open, setOpen] = useState(false);
    const [pagination, setPagination] = useState({
        pageSelected: 1,
        sizePerPage: 10,
        filterMOs: []
    });

    const [pageMO, setPageMO] = useState({
        pageableObj: [],
        totalElements: 0,
        pagination: pagination
    });


    const handleClose = () => {
        setOpen(false);
    };
    const handleToggle = () => {
        setOpen(!open);
    };

    // return anything from formatter to render into column
    // we will return a button component

    const linkFormatter = (id) => {
        let productUser = productUsers.filter(obj => obj.id === id)
        return (
            <>
                {productUser[0].isEmailVerified === 0 ? <div  style={{ marginLeft: '5px' }}> <button className="add-btn" onClick={(e) => verifyEmail(productUser[0])} >
                    {'Verify'}
                </button> </div> : <div className="ml-2" style={{ marginLeft: '20px' }}> <Check2Circle color="#01b007" title='verified' size={20} /></div>}

            </>
        );
    };

    const columns = [{
        dataField: 'name',
        text: 'Name',
        sort: true,
        type: 'text',
        value: ''
    },
    {
        dataField: 'customerId',
        text: 'Customer Id',
        sort: true,
        type: 'text',
        value: ''
    },
    {
        dataField: 'emailId',
        text: 'Email Id',
        sort: true,
        type: 'text',
        value: ''
    },
    {
        dataField: 'mobileNo',
        text: 'Mobile No',
        sort: true,
        type: 'text',
        value: ''
    },
    {
        dataField: 'unit',
        text: 'unit',
        sort: true,
        type: 'text',
        value: ''
    },
    {
        dataField: 'id',
        text: 'Verify Email',
        sort: true,
        type: 'text',
        rowFormatter: linkFormatter,
        columnFormatter: (handleFilter) => (
            <div></div>
        )
    }
    ]

    const verifyEmail = (productUser) => {
        setShowEmailVerification(true);
        setProductUserEmailVer(productUser);

    }
    const updateParent = () => {
        handleTableChange([], 1, pagination.sizePerPage);
    }

    const updateParentEditMode = (data) => {
        handleTableChange([], pagination.pageSelected, pagination.sizePerPage);
    }

    const expandRow = {
        renderer: row => <EditProductUser data={row} updateParent={updateParentEditMode} />
    }

    const [updatePumps, setUpdatePumps] = useState([]);

    useEffect(() => {
        handleTableChange([], pagination.pageSelected, pagination.sizePerPage);
    }, []);

    useEffect(() => {
        handleTableChange([], pagination.pageSelected, pagination.sizePerPage);
    }, [updatePumps.length]);

    const retreiveProductUsers = (pagination, actionType) => {
        if (actionType === "pagination") {
            handleToggle()
        }

        ManageProductService.getAllProductUsers(pagination)
            .then(response => {
                const updatedList = response.data.pageableObj;
                setProductUsers(updatedList)
                setPageMO({ ...pageMO, ['pageableObj']: response.data.pageableObj, ['totalElements']: response.data.totalElements, ['pagination']: pagination });
                handleClose();
            })
            .catch(e => {
                setMessage(e.response ? e.response.data.message : Constants.ERROR_MSG + " product user details");
                setAlertHeading("Error!");
                setAlertVariant("danger");
                setShow(true);
                setLoading(false);
                handleClose();
            });
    }

    const handleTableChange = (filters, page, sizePerPage, actionType) => {
        pagination.pageSelected = page;
        pagination.sizePerPage = sizePerPage;
        pagination.filterMOs = filters;
        retreiveProductUsers(pagination, actionType);
    }

    const deleteProductUsers = (pumpList) => {
    }

    const [showButton, setShowButton] = useState(true);
    const [showMessage, setShowMessage] = useState(false);
    const nodeRef = useRef(null);

    return (
        <div className="col-md-10">
            <div
                className="modal show"
                style={{ display: 'block', position: 'initial' }}
            >
                <Modal size="lg" aria-labelledby="contained-modal-title-vcenter"
                    centered show={showEmailVerification} onHide={(e) => setShowEmailVerification(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title className="ml-5">Step : Email Verification</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <ProductUserEmailVerification data={productUserEmailVer} updateParent={updateParentEditMode}></ProductUserEmailVerification>
                    </Modal.Body>
                </Modal>
            </div>
            <div className="col-md-10 float-right">
                <div className="row"><div className="float-left col-md-2 p-0"><h4>Product Users</h4></div>{showButton && (
                    <div className="float-left col-md-9">  <h5 className="mt-1">  <Link className="addNewHeading"
                        onClick={() => setShowMessage(true)}
                    >
                        Add Product User
                    </Link></h5></div>
                )
                }</div>
                {show && (
                    <Alert className="col-md-6" variant={alertVariant} onClose={() => setShow(false)} dismissible>
                        <Alert.Heading>{alertHeading}</Alert.Heading>
                        <p>{message}</p>
                    </Alert>

                )}
                <div className="row">
                    <CSSTransition
                        in={showMessage}
                        nodeRef={nodeRef}
                        timeout={500}
                        classNames="newBox"
                        unmountOnExit
                        onEnter={() => setShowButton(false)}
                        onExited={() => setShowButton(true)}
                    >
                        <div className="addNewBox p-0"
                            ref={nodeRef}
                        ><div className="col-md-12 p-0">
                                <div className="float-left col-md-10"><h5 style={{ marginLeft: '35px', marginTop: '5px' }}>Add Product User</h5> </div>
                                <div className="col-md-2 float-right">
                                    <button className="float-right addNewClose"
                                        onClick={() => setShowMessage(false)}
                                    >
                                        X
                                    </button>
                                </div>
                            </div>
                            <div className="p-0  ml-0" style={{ height: 'auto' }}>
                                <AddProductUser updateParent={updateParent} />
                            </div>
                        </div>
                    </CSSTransition>
                </div>
                <div className="row mt-3">
                    <Loader openDialog={open}></Loader>
                    <FMTable columns={columns} dataList={productUsers} pageMO={pageMO} handlePagination={handleTableChange} expandRow={expandRow} showFilter={true} />
                </div>
            </div>
        </div >
    )
}
export default ProductUserList;