import React from "react";
import { NumericFormat } from 'react-number-format';
const NumberInput = (props) => {

    return (


        <NumericFormat style={props.style} autoComplete={props.autoComplete ? props.autoComplete : 'off'} id={props.id} name={props.name} fixedDecimalScale={props.isDecScaleEnabled ? props.isDecScaleEnabled : false} decimalScale={props.decimalScale ? props.decimalScale : 0}
            value={props.value} allowNegative={props.allowNegative ? props.allowNegative : false} disabled={props.disabled}
            className={props.className} thousandSeparator={props.isThousandSepEnabled} thousandsGroupStyle={props.thousandsGroupStyle} onValueChange={(values) => {
                const { formattedValue, value } = values;
                // formattedValue = $2,223
                // value ie, 2223
                props.onChange(value)
            }} />
    )

}
export default NumberInput;