import React, { useEffect, useState } from "react";
import { Select, Table, InputLabel, MenuItem, FormControl, StyledEngineProvider } from '@mui/material';
import { Link } from "react-router-dom";
import AlertDialog from "./AlertDialog";


export default function FMTable({ columns, dataList, pageMO, handlePagination, expandRow, showFilter, showDelete, deleteHandler }) {

    let [divEle, setDivEle] = '';

    const [isAllDeleted, setIsAllDeleted] = useState(false);

    const [rowSize, setRowSize] = useState(pageMO?.pagination?.sizePerPage);
    const initFilterField = {
        field: '',
        value: ''
    }

    const TableChangeType = {
        pagination: 'pagination',
        filter: 'filter',
        clearFilter: 'clearFilter',
    }
    const [filterField, setFilterField] = useState(initFilterField);
    const [filters, setFilters] = useState([]);
    const [filterColumns, setFilterColumns] = useState([]);
    const [dialogShow, setDialogShow] = useState(false);
    const [event, setEvent] = useState({
        field: '',
        value: ''
    });
    const [events, setEvents] = useState([]);

    // State variable to keep track of all the expanded rows
    // By default, nothing expanded. Hence initialized with empty array.
    const [expandedRows, setExpandedRows] = useState([]);

    // State variable to keep track which row is currently expanded.
    const [expandState, setExpandState] = useState([]);

    const totalPaginationSize = 5;
    const handlePageAction = (filter, selectedpage, sizePerPage) => {
        resetDeleteAction();
        handlePagination(filter, selectedpage, sizePerPage, TableChangeType.pagination);
        setExpandedRows([]);
    }
    const PageTable = (pageObj, noOfPages) => {
        const arr = [];
        let sizePerPage = rowSize;
        let pageSelected = pageObj.pagination.pageSelected;
        if (pageSelected > 1) {
            let selectedPage = pageSelected - 1
            if (pageSelected - 1 !== 1) {
                arr.push(<td className='pagination float-left pg-border-left-style' onClick={(e) => handlePageAction(filters, 1, sizePerPage)}><Link className='pg-color link' style={{textDecoration : 'none'}}>{'<<'}</Link> </td>);
                arr.push(<td className='pagination float-left' onClick={(e) => handlePageAction(filters, selectedPage, sizePerPage)}><Link className='pg-color link'>{'<'}</Link> </td>);
            } else {
                arr.push(<td className='pagination float-left pg-border-left-style' onClick={(e) => handlePageAction(filters, selectedPage, sizePerPage)}><Link className='pg-color link'>{'<'}</Link> </td>);
            }
        }
        let selectedPage = pageSelected - 1;
        let startPagination = (Math.floor(selectedPage / totalPaginationSize) * totalPaginationSize) + 1;
        let endPagination = startPagination + totalPaginationSize;
        if (endPagination > noOfPages) {
            endPagination = noOfPages + 1;
        }


        for (let i = startPagination; i < endPagination; i++) {
            if (pageSelected === i) {
                arr.push(
                    <td className='pagination pg-selected' onClick={(e) => handlePageAction(filters, i, sizePerPage)}><Link className='pg-color-selected link' >{i}</Link> </td>
                );
            } else {
                arr.push(
                    <td className='pagination  pg-color' onClick={(e) => handlePageAction(filters, i, sizePerPage)}><Link className='pg-color link'>{i}</Link> </td>
                );
            }

        }

        if (pageSelected !== noOfPages && pageSelected < noOfPages) {
            let selectedPage = pageSelected + 1
            if (pageSelected + 1 !== noOfPages) {
                arr.push(<td className='pagination float-left' onClick={(e) => handlePageAction(filters, selectedPage, sizePerPage)}><Link className='pg-color link'>{'>'}</Link> </td>);
                arr.push(<td className='pagination float-left pg-border-right-style' onClick={(e) => handlePageAction(filters, noOfPages, sizePerPage)}><Link className='pg-color link'>{'>>'}</Link> </td>);
            } else {
                arr.push(<td className='pagination float-left pg-border-right-style' onClick={(e) => handlePageAction(filters, selectedPage, sizePerPage)}><Link className='pg-color link'>{'>'}</Link> </td>);
            }
        }
        return (
            <div className="col-md-12 p-0 mt-3 mb-3" style={{ overflow: 'hidden', height: 'auto' }}>
                <div className="col-md-1 float-left pl-0" style={{ overflow: 'hidden', height: 'auto' }}>
                       
                    <FormControl  variant="standard">
                    <InputLabel  id="demo-simple-select-standard-label">Rows</InputLabel>
                        <Select  labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
                            value={rowSize}
                            onChange={(e) => handleRowsChange(e)}
                            autoWidth
                            label="Rows Per Page"
                        >
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={20}>20</MenuItem>
                            <MenuItem value={50}>50</MenuItem>
                            <MenuItem value={100}>100</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div className="col-md-11 center p-0" style={{ overflow: 'hidden', height: 'auto' }}>
                    <Table className='float-left' >
                        <tbody><tr>{arr}</tr></tbody>
                    </Table>
                </div>

            </div>


        )
    }

    function Paginate({ pageObj }) {
        let totalItemSize = pageObj.totalElements;
        let noOfPages = Math.floor(totalItemSize / rowSize);
        let remainder = totalItemSize % rowSize;
        if (remainder > 0) {
            noOfPages = noOfPages + 1;
            return PageTable(pageObj, noOfPages);
        } else {
            return PageTable(pageObj, noOfPages);
        }
    }

    const typeHelper = (data, column) => {
        if (column.type === 'text') {
            return <td>{!column?.rowFormatter ? data : column.rowFormatter(data)}</td>
        } else if (column.type === 'select') {
            return <td >{!column?.rowFormatter ? data : column.rowFormatter(data)}</td>;
        }
    }

    const helper = (data, column) => {
        let split = column.dataField.split(".");
        let extractedData = findVal(data, split);
        let divEle = typeHelper(extractedData, column);
        return divEle;
    }

    const findVal = (data, split, column) => {
        split.forEach(str => {
            let object = data[str];
            if (object && typeof object === "object") {
                var index = split.indexOf(str)
                let splitVal = split.splice(index, 1);
                findVal(object, split);
            } else {
                var index = split.indexOf(str)
                if (divEle != '') {
                    divEle = object
                }
                return divEle;
            }
            return divEle;
        })
        return divEle;
    }

    const handleRowsChange = (e) => {
        setRowSize(e.target.value);
        handlePagination(filters, 1, e.target.value, TableChangeType.pagination);
    }

    const handleFilterField = (column, e) => {
        const value = e.target.value;
        setFilters(old => old.map((filterField) => {
            if (filterField.field === column.dataField) {
                filterField.value = value;
            }
            return filterField;
        }));
        setFilterColumns(old => old.map((filterCol) => {
            if (filterCol.dataField === column.dataField) {
                filterCol.value = value;
            }
            return filterCol;
        }));
        handlePagination(filters, 1, rowSize);
        resetDeleteAction();
    }


    const [expandRowClass, setExpandRowClass] = useState("");
    const [rowIndex, setRowIndex] = useState(0);
    /**
   * This function gets called when show/hide link is clicked.
   */
    //https://stackblitz.com/edit/react-hooks-dynamically-add-table-row-expand-collapse?file=index.js

    const [showButton, setShowButton] = useState(true);
    const handleExpandRow = (event, index) => {
        setRowIndex(index);
        const currentExpandedRows = expandedRows;
        const isRowExpanded = currentExpandedRows.includes(index);
        setExpandState(expandState);
        // If the row is expanded, we are here to hide it. Hence remove
        // it from the state variable. Otherwise add to it.
        const newExpandedRows = isRowExpanded ?
            currentExpandedRows.filter(id => id !== index) :
            currentExpandedRows.concat(index);
        setExpandState(newExpandedRows);
        //https://stackoverflow.com/questions/49517309/how-to-slide-or-hide-a-div-with-transition-in-react
        setExpandedRows(newExpandedRows);
        isRowExpanded ? (setExpandRowClass('')) : (setExpandRowClass('coolclass'));
        setExpandAll(newExpandedRows.length > 0 ? true : false);
    }

    const [expandAll, setExpandAll] = useState(false);
    const handleExpandAll = (event, index) => {
        const affectedRows = !expandAll ? dataList.map((obj, index) => {
            return index;
        }) : [];
        setExpandState(affectedRows);
        setExpandedRows(affectedRows);
        setExpandAll(affectedRows.length > 0 ? true : false);
    }

    const clearFilters = () => {
        setFilters(old => old.map((filterField) => {
            filterField.value = '';
            return filterField;
        }));

        setFilterColumns(old => old.map((filterField, index) => {
            if (filterField.type === 'select') {
                filterField.clearFilter();
            } else {
                filterField.value = '';
            }
            return filterField;
        }));
        handlePagination(filters, 1, rowSize, TableChangeType.clearFilter);
        resetDeleteAction();
    }

    const [enableFilter, setEnableFilter] = useState(true);
    useEffect(() => {
        setEnableFilter(showFilter !== undefined ? showFilter : true);

    }, showFilter);

    useEffect(() => {
        setExpandedRows([]);
        setExpandState([]);
        setExpandAll(false);
    }, [dataList?.length]);

    useEffect(() => {
        columns.map((column) => {
            let filterFieldObj = { 'field': column.dataField, 'value': '' }
            setFilters(old => [...old, filterFieldObj])
        })
        //setFilterColumns((columns).map( x => ({...x})));
        setFilterColumns(columns);
    }, []);

    const tableFilter = (column) => {
        if (column.columnFormatter === undefined) {
            return <input type='text' placeholder={column.placeholder} className="input-style" value={column.value} style={{ width: '80%' }} onChange={(e) => handleFilterField(column, e)} />
        } else {
            return column.columnFormatter((e) => handleFilterField(column, e))
        }
    }

    const [deleteIds, setDeleteIds] = useState([]);
    const [deleteCount, setDeleteCount] = useState(0);
    const [deleteBtnStatus, setDeleteBtnStatus] = useState(false);

    const resetDeleteAction = () => {
        setDeleteCount(0);
        setDeleteIds([]);
        setIsAllDeleted(false);
        setDeleteBtnStatus(false);
        dataList.map(obj => {
            obj.isChecked = false;
            return obj;
        });
    }

    const callDeletehandler = () => {
        setDialogShow(false);
        setDeleteBtnStatus(true);
        deleteHandler(deleteIds);
        resetDeleteAction();
    }

    function deleteAction() {
        if (deleteCount > 0) {
            setDialogShow(true);
        }
    }

    const handleDeleteCheck = (event, data) => {
        dataList.map(obj => {
            if (obj.id === data.id) {
                return event.target.checked ? data.isChecked = true : data.isChecked = false;
            }
        });
        let addDeleteObjs = (event.target.checked ? deleteIds.concat(data) : deleteIds.filter(obj => obj.id !== data.id));

        setDeleteIds(addDeleteObjs);
        setDeleteCount(addDeleteObjs.length);
    }

    const handleDeleteAllCheck = (event) => {
        let deleteAllIds = [];
        if (event.target.checked) {
            setIsAllDeleted(true);
            deleteAllIds = dataList.map(obj => {
                obj.isChecked = true;
                return obj;
            });
        } else {
            setIsAllDeleted(false);
            dataList.map(obj => {
                obj.isChecked = false;
                return obj;
            });
        }
        setDeleteIds(deleteAllIds);
        setDeleteCount(deleteAllIds.length);
    }

    const yesBtnHandler = {
        name: 'Yes',
        callAction: callDeletehandler
    };

    const noBtnHandler = {
        name: 'No',
    };

    return (
        <div className="col-md-12 p-0">
            {dialogShow && (
                <AlertDialog openDialog={true} dialogShowStateVar={setDialogShow} dialogTitle={"Delete Action!!!"}
                    message={"Delete action cant be reverted back, are you sure want to contiue?"}
                    noBtnHandler={noBtnHandler} yesBtnHandler={yesBtnHandler} />
            )}

            {showDelete ? 
                <div className="float-left col-md-8 p-0 mb-2" >
                    <button
                        className="delete-btn ml-0 float-left"
                        variant="contained"
                        type="button" onClick={deleteAction} disabled={deleteBtnStatus}
                    >
                        {deleteBtnStatus ? 'Deleting... [' + deleteCount + ']' : 'Delete [' + deleteCount + ']'}
                    </button></div>: null}
            {enableFilter ?
                <div className="float-right mb-2"><Link className="fm-ClearFilters" onClick={(e) => clearFilters()}>clear filters</Link></div> : <div></div>}

            <Table className="fmanTable">
                <thead>
                    <tr> {showDelete !== undefined ? <th style={{ width: '20px', paddingTop: '15px' }}><input type="checkbox" checked={isAllDeleted} onClick={(e) => handleDeleteAllCheck(e)}></input></th> : null}
                        {expandRow !== undefined ? <th style={{ width: '20px' }} onClick={handleExpandAll}> {expandAll === false ? '(' + '+' + ')' : '(' + '-' + ')'}</th> : null}
                        {filterColumns.map((column, index) =>
                            <th> <div style={{ width: '100%' }}> {column.text}</div>
                                {enableFilter ?
                                    tableFilter(column) : <div></div>}
                            </th>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {dataList?.length > 0 ? dataList?.map((data, index) => {
                        return <><tr>
                            {showDelete !== undefined ? <td style={{ paddingTop: '14px', width: '20px' }}><input type="checkbox" checked={data.isChecked} onClick={(e) => handleDeleteCheck(e, data)}></input></td> : null}
                            {expandRow !== undefined ? <td onClick={event => handleExpandRow(event, index)} style={{ width: '20px' }}>
                                {
                                    expandState.includes(index) ?
                                        '(-)' : '(+)'
                                }</td> : null}
                            {columns.map((column) => {
                                const split = column.dataField.split(".");
                                if (split.length > 1) {
                                    return helper(data, column)
                                } else {
                                    return typeHelper(data[column.dataField], column)
                                }
                            })}
                        </tr>
                            {

                                (expandedRows.includes(index)) ?
                                    (<tr id='expandRow' style={{ height: '100%' }} className={expandState.includes(index) ?
                                        'coolclass' : ''}><td colSpan={showDelete === undefined ? columns.length + 1 : columns.length + 2} style={{ height: '100%' }} > <div >{expandRow.renderer(data)}</div></td> </tr>) : null
                            }
                        </>
                    }) : <tr className="norecord">
                        <td colSpan={showDelete === undefined ? columns.length + 1 : columns.length + 2}><p> No records to display</p></td>
                    </tr>
                    }

                </tbody>
            </Table>
            {!!pageMO && <Paginate pageObj={pageMO}></Paginate>}


        </div>
    );
}
