import { Alert, Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import ManageProductService from "../service/ManageProductService";
import Autocomplete from "../common/Autocomplete";
import ReactDatePicker from "react-datepicker";
import { format } from 'date-fns-tz';
import { parse, parseISO } from "date-fns";

const EditSubscription = ({ data, updateParent }) => {
    const [message, setMessage] = useState("");
    const [alertVariant, setAlertVariant] = useState("");
    const [alertHeading, setAlertHeading] = useState("");
    const [show, setShow] = useState(false);

    const [subscription, setSubscription] = useState(data);
    const [productUsers, setProductUsers] = useState([]);
    const [productUser, setProductUser] = useState(data.productUserMO);
    const [startDate, setStartDate] = useState();

    const [plans, setPlans] = useState([]);
    const [plan, setPlan] = useState(data.planMO);

    const [updateSubmitted, setUpdateSubmitted] = useState(false);
    const [deleteSubmitted, setDeleteSubmitted] = useState(false);

    const [subscriptionError, setSubscriptionError] = useState({
        productUserError: '',
        planError: '',
        startDateError: ''
    });

    const handleInputChange = event => {
        const { name, value } = event.target;
        setProductUser({ ...productUser, [name]: value });
    };

    const handleInputChangeRaw = (value, name) => {
        setProductUser({ ...productUser, [name]: value });
    };

    useEffect(() => {
        setStartDate(parse(data.startDate, "dd.MM.yyyy", new Date()));
        retrievePlans();
    }, [data]);
    
    const retrievePlans = () => {
        ManageProductService.getAllPlanWOPagination()
            .then(response => {
                setPlans(response.data);
                console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    };

     const handleValidation = () => {
        if (!productUser?.id) {
            setSubscriptionError({ ...subscriptionError, 'productUserError': 'Please select product user' });
            return false;
        } else if (!plan?.id) {
            setSubscriptionError({ ...subscriptionError, 'productUserError': '', 'planError': 'Please select plan' });
            return false;
        } else if (!subscription?.startDate) {
            setSubscriptionError({ ...subscriptionError, 'productUserError': '', 'planError': '', 'startDateError': 'Please enter start date' });
            return false;
        }
        setSubscriptionError({ ...subscriptionError, 'productUserError': '', 'planError': '', 'startDateError': '' });
        return true;
    }

    const updateSubscription = () => {
        setUpdateSubmitted(true);
        if (handleValidation()) {
            subscription.productUserMO = productUser;
            subscription.planMO = plan;
            subscription.startDate = format(startDate, "dd.MM.yyyy", { timeZone: "Asia/Kolkata" }),
            ManageProductService.updateSubscription(subscription)
                .then(response => {
                    updateParent()
                    setShow(true);
                    setTimeout(() => {
                        setShow(false);
                    }, 3000);
                    setAlertHeading("Success!!");
                    setAlertVariant("success");
                    setMessage("Successfully updated");
                    setUpdateSubmitted(false);
                })
                .catch(e => {
                    setShow(true);
                    setMessage(e.response.data.message);
                    setAlertHeading("Error!");
                    setAlertVariant("danger");
                    setUpdateSubmitted(false);
                })
        }
        setUpdateSubmitted(false);
    }

    const deleteSubscription = () => {
        setDeleteSubmitted(true);
        ManageProductService.deleteSubscription(subscription)
            .then(response => {
                console.log(response.data);
                updateParent()
                setDeleteSubmitted(false);
            })
            .catch(e => {
                setMessage(e.response.data);
                setAlertHeading("Error!");
                setAlertVariant("danger");
                setDeleteSubmitted(false);
                setShow(true);
            });
    };


    return (
        <div className="p-5 pt-0">
            <div className="col-md-12 float-right">
                {show &&
                    <div className="col-md-12 float-left">
                        <Alert style={{ width: 'auto' }} variant={alertVariant} onClose={() => setShow(false)} dismissible>
                            <Alert.Heading>{alertHeading}</Alert.Heading>
                            <p>{message}</p>
                        </Alert></div>}
                <div className="adminFormLeft">
                    <label className='required'>Customer Id</label> <span className='errorMsg'>  {subscriptionError.productUserError}</span>
                    <input className="form-control"  type="text"  value={productUser ? (productUser?.customerId) + (" - ") + (productUser?.name) : ''} disabled={true} />
                </div>

                <div className="adminFormRight">
                    <label className='required'>Plan</label> <span className='errorMsg'>  {subscriptionError.planError}</span>
                    <Autocomplete items={plans}
                        searchField={'type'}
                        renderItem={(item) =>
                            <>
                                {item.type}
                            </>

                        }
                        onChange={(event, value) => { setPlan({ ...plan, ['type']: value }) }}
                        onSelect={(value, item) => {
                            setPlan(item ? item : undefined)
                        }}
                        value={plan?.type} />
                </div>

                <div className="adminFormLeft mt-2">
                    <label className='required'>Start Date</label><span className='errorMsg'>  {subscriptionError.startDateError}</span>
                    <ReactDatePicker autoComplete="off" selected={startDate} name="startDate" id="startDate" className="form-control" onChange={(date) =>  setStartDate(date)} dateFormat="dd/MM/yyyy" />
                </div>
                <div className="col-md-12 float-left mt-3" style={{ paddingBottom: '10px' }}>
                    <div className="float-right">  <button
                        className="update-btn float-right"
                        variant="contained" type="button"
                        onClick={(e) => updateSubscription()} disabled={updateSubmitted}
                    >{updateSubmitted ? 'Updating....' : 'Update'}
                    </button>
                    </div>
                    <div className="float-right" style={{ marginRight: '8px' }}>
                        <button
                            className="delete-btn float-right"
                            variant="contained"
                            disabled={deleteSubmitted}
                            type="button"
                            onClick={(e) => deleteSubscription()}
                        >
                            {deleteSubmitted ? 'Deleting...' : 'Delete'}
                        </button>
                    </div>
                </div>


            </div>
        </div>
    )
}

export default EditSubscription;