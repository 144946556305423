
import { Link, StyledEngineProvider } from '@mui/material';
import React, { useState, useRef } from "react";
import '../../login.css';
import { useNavigate } from 'react-router-dom';
import UserService from '../service/UserService';
import Constants from '../../Constants';

const Logout = (props) => {
    const navigate = useNavigate();
    const logoutAction = () => {
        navigate("/anchor/logout");
    }
    return (
        <StyledEngineProvider injectFirst>
            <div style={{ marginTop: '0px', marginLeft: '10px' }}><Link className="logout" onClick={e => logoutAction()}>Logout</Link></div>
        </StyledEngineProvider>
    )

}

export default Logout;