import http from "../../http-commons"


const verifyLogin = (data) => {
    return http.post("/verifyLogin", data)
}

const logoutAction = (data) => {
    return http.put('/logoutAction', data);
}


const createSuperAdminUser = (data) => {
    return http.post("/createSuperAdminUser", data);
  };

  const getSuperAdminUserForProductUser = (id) => {
    return http.get(`/getSuperAdminUserForProductUser/${id}`);
  };

  
export default {
    verifyLogin,
    logoutAction,
    createSuperAdminUser,
    getSuperAdminUserForProductUser,
};