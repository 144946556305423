import React, { useEffect, useRef, useState } from "react";
import '../../password.css';

const PasswordBox = (props) => {

    // with getInitialState we can set the state
    // the Component is in when the site is rendered.
    // In this case the Input Type is password and the wording
    // of the trigger "button" is 'Show' 
    //
    // getInitialState returns an Object having as many properties
    // as we need. They'll be accessible via this.state.PROPERTYNAME,
    // e.g. this.state.type
    const [state, setState] = useState({
        type: 'password',
        wording: 'Show'
    });
    // This little helper function will change the State of our
    // Component. It can be named anything, but I chose changeState
    // because it affects all available states.
    const changeState = (e) => {
        var oldState = state.type;
        var isTextOrHide = (oldState === 'password');
        var newState = (isTextOrHide) ? 'text' : 'password';
        var newWord = (isTextOrHide) ? 'Hide' : 'Show';
        setState({
            type: newState,
            wording: newWord
        })
    };
    // As always, we need a render Function that returns 
    // the HTML of our component 
    return (
        <div className="p-0 passwordBox">

            <input type={state.type} className="form-control" name={props.name} id={props.id} value={props.value} onChange={(e)=> props.onChange(e)} />
            <span className="password-trigger" onClick={changeState}>{state.wording}</span>
        </div>
    )

}
export default PasswordBox;