import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AnchorApp from './AnchorApp';
//login
import Login from './components/access/Login';
//login
//global error page
import ErrorPage from './ErrorPage';
import Constants from './Constants'
import ProductUserList from './components/manageProduct/ProductUserList';
import SubscriptionList from './components/manageProduct/SubscriptionList';
import PlanList from './components/manageProduct/PlanList';
import Logout from './components/access/Logout';
import AddSuperAdmin from './components/manageProduct/AddSuperAdmin';

function App() {
    return (
        <Router>
            <Routes>
                <Route path={'/anchor/*'} element={<AnchorApp />}>
                    <Route path="productUser" element={<ProductUserList />} /> //Nested Route
                    <Route path="subscription" element={<SubscriptionList />} /> //Nested Route
                    <Route path="plan" element={<PlanList />} /> //Nested Route
                    <Route path="superAdmin" element={<AddSuperAdmin/>} />
                </Route>
              /**Login */
                <Route exact path={"/anchor"} element={<Login />} />
                <Route exact path={"/anchor/logout"} element={<Login />} />
                <Route path={Constants.ERROR_PAGE} element={<ErrorPage />} />
            </Routes>
        </Router>
    );
}

export default App;