import { Alert, Button } from "react-bootstrap";
import { useState } from "react";
import ManageProductService from "../service/ManageProductService";
import NumberInput from "../common/NumberInput";

const AddPlan = ({ updateParent }) => {
    const [submitted, setSubmitted] = useState(false);
    const [message, setMessage] = useState("");
    const [alertVariant, setAlertVariant] = useState("");
    const [alertHeading, setAlertHeading] = useState("");
    const [show, setShow] = useState(false);
    const initialPlan = {
        id: '',
        type: '',
        validity: '',
        price : ''
    }
    const [plan, setPlan] = useState(initialPlan);

    const [planError, setPlanError] = useState({
        typeError: '',
        validityError: '',
        priceError: ''
    });

    const handleInputChange = event => {
        const { name, value } = event.target;
        setPlan({ ...plan, [name]: value });
    };

    const handleInputChangeRaw = (value, name) => {
        setPlan({ ...plan, [name]: value });
    };

    const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

    const handleValidation = () => {
        if (!plan.type) {
            setPlanError({ ...planError, 'typeError': 'Please enter plan type' });
            return false;
        } else if (!plan.validity) {
            setPlanError({ ...planError, 'typeError': '', 'validityError': 'Please enter validity' });
            return false;
        } 
        else if (!plan.price) {
            setPlanError({ ...planError, 'typeError': '', 'validityError': '', 'priceError' : 'Please enter price' });
            return false;
        } 
        setPlanError({ ...planError, 'typeError': '', 'validityError': '', 'priceError' : '' });
        return true;
    }

    const savePlan = () => {
        setSubmitted(true);
        if (handleValidation()) {
            ManageProductService.savePlan(plan)
                .then(response => {
                    updateParent()
                    setShow(true);
                    setTimeout(() => {
                        setShow(false);
                    }, 3000);
                    setAlertHeading("Success!!");
                    setAlertVariant("success");
                    setMessage("Successfully added");
                    setSubmitted(false);
                    setPlan(initialPlan);
                })
                .catch(e => {
                    setShow(true);
                    setMessage(e.response.data);
                    setAlertHeading("Error!");
                    setAlertVariant("danger");
                    setSubmitted(false);
                })
        }
        setSubmitted(false);
    }
    return (
        <div className="p-5">
            <div className="col-md-12 float-right">
                {show &&
                    <div className="col-md-12 float-left">
                        <Alert style={{ width: 'auto' }} variant={alertVariant} onClose={() => setShow(false)} dismissible>
                            <Alert.Heading>{alertHeading}</Alert.Heading>
                            <p>{message}</p>
                        </Alert></div>}
                <div className="adminFormLeft">
                    <lable className='required'>Plan Type</lable> <span className='errorMsg'>  {planError.typeError}</span>
                    <input className="form-control" type="text" value={plan.type} onChange={(e) => handleInputChange(e)}
                        id="type"
                        name="type"
                    ></input>
                </div>

                <div className="adminFormRight">
                    <lable className='required'>Validity</lable> <span className='errorMsg'>  {planError.validityError}</span>
                    <NumberInput autoComplete="off" id="validity" name="validity" isDecScaleEnabled={true} decimalScale={0}
                        value={plan.validity} className="form-control"
                        onChange={(value) => handleInputChangeRaw(value, "validity")}></NumberInput>
                </div>
                
                <div className="adminFormLeft">
                    <lable className='required'>Price</lable> <span className='errorMsg'>  {planError.priceError}</span>
                    <NumberInput autoComplete="off" id="price" name="price" isDecScaleEnabled={true} decimalScale={0}
                        value={plan.price} className="form-control"
                        onChange={(value) => handleInputChangeRaw(value, "price")}></NumberInput>
                </div>

                
                <div className="col-md-12 float-right mt-3 mb-2">
                    <button color="primary"
                        variant="contained"
                        className="float-right add-btn"
                        onClick={(e) => savePlan(e)} disabled={submitted}>
                        {submitted ? 'Saving...' : 'Save'}
                    </button>
                </div></div>
        </div>
    )
}

export default AddPlan;