import { useEffect, useState } from "react";
import ManageProductService from "../service/ManageProductService";
import { red } from "@mui/material/colors";



const ProductUserEmailVerification = ({ data, updateParent }) => {
    const [message, setMessage] = useState({
        color: '',
        message: ''
    });
    const [productUser, setProductUser] = useState(data);

    useEffect(() => {
        createEmailVerificationCode(data);
    }, [data])

    const createEmailVerificationCode = (data) => {
        setMessage({ ...message, ['text']: 'Please wait, sending code to email...', ['color']: 'red' })
        ManageProductService.genEmailVerificatoinCode(data).
            then((response) => {
                setMessage({ ...message, ['text']: 'Verification code send successfully', ['color']: 'green' })
            }).catch((e) => {
                setMessage(e.response.data)
            })
    }

    const handleInputChange = event => {
        const { name, value } = event.target;
        setProductUser({ ...productUser, [name]: value });
    };

    const verifyEmailVerificationCode = () => {
        ManageProductService.verifyEmailVerificationCode(productUser).
            then((response) => {
                updateParent();
                setMessage({ ...message, ['text']: 'successfully verified email', ['color']: 'green' })
            }).catch((e) => {
                setMessage({ ...message, ['text']: e.response.data, ['color']: 'red' })
            })
    }

    return (
        <div>

            <div className="col-md-12 float-left"><label style={{ fontSize: '15px', color: message.color }}>{message.text}</label></div>
            <div className="col-md-12 float-left">
                <div className="col-md-6 float-left">
                    <label htmlFor="description">Enter Code</label> <span className='errorMsg'> </span>
                    <input
                        type="text"
                        className="form-control"
                        id="emailVerificationCode"
                        name="emailVerificationCode"
                        value={productUser.emailVerificationCode}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="col-md-5 float-left mt-4" style={{ marginLeft: '20px' }}>
                    <button color="primary"
                        variant="contained"
                        className="float-left add-btn" style={{ paddingLeft: '10px', paddingRight: '10px' }} onClick={((e) => verifyEmailVerificationCode())}> Verify</button>
                </div>
            </div>
        </div>
    )

}
export default ProductUserEmailVerification;