import React, { useState, useEffect, useRef } from "react";
import Alert from 'react-bootstrap/Alert';
import { Button, Link } from '@mui/material';
import UserService from "../service/UserService";
import PasswordBox from "../common/PasswordBox";
import Autocomplete from "../common/Autocomplete";
import ManageProductService from "../service/ManageProductService";

const AddSuperAdmin = ({ updateParent }) => {
    const [submitted, setSubmitted] = useState(false);
    const [message, setMessage] = useState("");
    const [alertVariant, setAlertVariant] = useState("");
    const [alertHeading, setAlertHeading] = useState("");
    const [show, setShow] = useState(false);
    const [userRoleSelected, setUserRoleSelected] = useState('DEFAULT');
    const [productUsers, setProductUsers] = useState([]);
    const initialProductUser = {
        id: '',
        customerId: ''
    }
    const [productUser, setProductUser] = useState(initialProductUser);
    const initialAppUserState = {
        id: '',
        userName: '',
        userRoleMO: {
            id: ''
        },
        productUserMO: {
            id: ''
        },
        userPassword: '',
        confirmPassword: ''
    }
    const [appUser, setAppUser] = useState(initialAppUserState);
    const [userRoles, setUserRoles] = useState([]);
    const handleInputChange = event => {
        const { name, value } = event.target;
        setAppUser({ ...appUser, [name]: value });
    };

    const [appUserError, setAppUserError] = useState({
        userNameError: '',
        userRoleError: '',
        userPasswordError: '',
        confirmPasswordError: ''
    })

    useEffect(() => {
        retrieveProductUsers();
    }, []);

    const retrieveProductUsers = () => {
        ManageProductService.getAllProductUserWOPagination()
            .then(response => {
                setProductUsers(response.data);
                setSubmitted(false);
                console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    };

    const handleValidation = () => {
        if (!appUser?.userName) {
            setAppUserError({ ...appUserError, "userNameError": "Please enter user name" });
            return false;
        }
        if (!appUser?.userRoleMO.id || appUser.userRoleMO.id === 'DEFAULT') {
            setAppUserError({ ...appUserError, "userNameError": '', "userRoleError": "Please select fuel type" });
            return false;
        }
        if (!appUser?.userPassword) {
            setAppUserError({ ...appUserError, "userNameError": '', "userRoleError": "", "userPasswordError": "please enter password" });
            return false;
        } if (appUser.userPassword.length < 8) {
            setAppUserError({ ...appUserError, "userNameError": '', "userRoleError": "", "userPasswordError": "password length should be equal or greater than 8" });
            return false;
        } if (!appUser?.confirmPassword) {
            setAppUserError({ ...appUserError, "userNameError": '', "userRoleError": "", "userPasswordError": "", "confirmPasswordError": "please enter confirm password" });
            return false;
        } if (appUser.confirmPassword.length < 8) {
            setAppUserError({ ...appUserError, "userNameError": '', "userRoleError": "", "userPasswordError": "", "confirmPasswordError": "confirm password length should be equal or greater than 8" });
            return false;
        } if (appUser.userPassword !== appUser.confirmPassword) {
            setAppUserError({ ...appUserError, "userNameError": '', "userRoleError": "", "userPasswordError": "", "confirmPasswordError": "confirm password doent match with password" });
            return false;
        }
        setAppUserError({ ...appUserError, "userNameError": '', "userRoleError": '', "userPasswordError": '', "confirmPasswordError": '' });
        return true;
    }


    const handleSelectChange = event => {
        const selectedIndex = event.target.options.selectedIndex - 1;
        var locUserRole = userRoles[selectedIndex];
        setAppUser({ ...appUser, "userRoleMO": locUserRole });
        setUserRoleSelected(locUserRole === undefined ? 'DEFAULT' : locUserRole.id);
    };

    const getSuperAdmin = (id) => {
        UserService.getSuperAdminUserForProductUser(id)
            .then(response => {
                setAppUser(response.data)
            })
            .catch(e => {

            })
    }

    const saveAppUser = () => {
        setShow(false);
        setSubmitted(true);
        UserService.createSuperAdminUser(productUser)
            .then(response => {
                setAppUser(response.data);
                setSubmitted(false);
                setShow(true);
                setTimeout(() => {
                    setShow(false);
                }, 3000);
                setAlertHeading("Success!!");
                setAlertVariant("success");
                setMessage("Successfully added");
            }).catch(e => {
                setShow(true);
                setSubmitted(false);
                setAlertHeading("Error!");
                setAlertVariant("danger");
                setMessage(e.response.data);
            })
    }

    const [showButton, setShowButton] = useState(true);
    const [showAddBox, setShowAddBox] = useState(false);
    const nodeRef = useRef(null);

    return (
        <div className="p-0 mt-5">
            <div className="col-md-8 float-left" style={{ marginLeft: '15%' }}>
                {show &&
                    <div className="col-md-12 float-left">
                        <Alert style={{ width: 'auto' }} variant={alertVariant} onClose={() => setShow(false)} dismissible>
                            <Alert.Heading>{alertHeading}</Alert.Heading>
                            <p>{message}</p>
                        </Alert></div>}
                <div className="col-md-12 row">
                    <div className="col-md-5">
                        <label htmlFor="title">Select Product User</label><span className='errorMsg'>  { }</span>
                        <Autocomplete items={productUsers}
                            searchField={'customerId'}
                            renderItem={(item) =>
                                <>
                                    {item.customerId} - {item.name}
                                </>

                            }
                            onChange={(event, value) => { setProductUser({ ...productUser, ['customerId']: value }) }}
                            onSelect={(value, item) => {
                                setProductUser(item ? item : undefined);
                                (item && getSuperAdmin(item.id))
                            }}
                            value={productUser?.customerId} />
                    </div>
                    {!appUser?.id && productUser?.id &&
                        <div className="col-md-4 float-left mt-4">
                            <button color="primary"
                                variant="contained"
                                className="add-btn" onClick={(e) => saveAppUser()}  disabled={submitted}> {submitted ? "Generating Super Admin...": "Genereate Super Admin"} </button>
                        </div>}
                </div>
                {appUser.id && <>  <div className="adminFormLeft mt-5">
                    <label htmlFor="title">User Name</label><span className='errorMsg'>  {appUserError.userNameError}</span>
                    <input
                        type="text"
                        className="form-control"
                        id="userName"
                        required
                        value={productUser?.customerId}
                        autoComplete="off"
                        disabled={true}
                        onChange={handleInputChange}
                        name="userName"
                    />
                </div>
                    <div className="adminFormRight mt-5">
                        <label htmlFor="title">Password</label><span className='errorMsg'>  {appUserError.userPasswordError}</span>
                        <PasswordBox value={appUser.userPassword} onChange={(e) => {
                            setAppUser({ ...appUser, ['userPassword']: e.target.value });
                        }} />
                    </div></>}


            </div>
        </div>
    )
}
export default AddSuperAdmin;