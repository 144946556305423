import { useEffect, useRef, useState } from "react";
import AddProductUser from "./AddProductUser";
import { Alert, Button } from "react-bootstrap";
import { CSSTransition } from "react-transition-group";
import { Link } from "react-router-dom";
import Constants from "../../Constants";
import FMTable from "../common/FMTable";
import Loader from "../common/Loader";
import ManageProductService from "../service/ManageProductService";
import EditProductUser from "./EditProductUser";
import AddSubscription from "./AddSubcription";
import EditSubscription from "./EditSubscription";

const SubscriptionList = () => {


    // general variables//
    const [message, setMessage] = useState("");
    const [alertVariant, setAlertVariant] = useState("");
    const [alertHeading, setAlertHeading] = useState("");
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(true);
    // general variables//

    const [subscriptions, setSubscriptions] = useState([]);

    const [open, setOpen] = useState(false);
    const [pagination, setPagination] = useState({
        pageSelected: 1,
        sizePerPage: 10,
        filterMOs: []
    });

    const [pageMO, setPageMO] = useState({
        pageableObj: [],
        totalElements: 0,
        pagination: pagination
    });


    const handleClose = () => {
        setOpen(false);
    };
    const handleToggle = () => {
        setOpen(!open);
    };

    const columns = [{
        dataField: 'productUserMO.name',
        text: 'Product User',
        sort: true,
        type: 'text',
        value: ''
    },
    {
        dataField: 'productUserMO.customerId',
        text: 'Customer Id',
        sort: true,
        type: 'text',
        value: ''
    },
    {
        dataField: 'planMO.type',
        text: 'Plan',
        sort: true,
        type: 'text',
        value: ''
    },
    {
        dataField: 'startDate',
        text: 'Start Date',
        sort: true,
        type: 'text',
        value: ''
    },
    {
        dataField: 'endDate',
        text: 'endDate',
        sort: true,
        type: 'text',
        value: ''
    }
]

    const updateParent = () => {
        handleTableChange([], 1, pagination.sizePerPage);
    }

    const updateParentEditMode = (data) => {
        handleTableChange([], pagination.pageSelected, pagination.sizePerPage);
    }

    const expandRow = {
        renderer: row => <EditSubscription data={row}  updateParent={updateParentEditMode} />
    }

    const [updatePumps, setUpdatePumps] = useState([]);

    useEffect(() => {
        handleTableChange([], pagination.pageSelected, pagination.sizePerPage);
    }, []);

    useEffect(() => {
        handleTableChange([], pagination.pageSelected, pagination.sizePerPage);
    }, [updatePumps.length]);

    const retreiveSubscriptions = (pagination, actionType) => {
        if (actionType === "pagination") {
            handleToggle()
        }

        ManageProductService.getAllSubscription(pagination)
            .then(response => {
                const updatedList = response.data.pageableObj;
                setSubscriptions(updatedList)
                setPageMO({ ...pageMO, ['pageableObj']: response.data.pageableObj, ['totalElements']: response.data.totalElements, ['pagination']: pagination });
                handleClose();
            })
            .catch(e => {
                setMessage(e.response ? e.response.data : Constants.ERROR_MSG + " product user details");
                setAlertHeading("Error!");
                setAlertVariant("danger");
                setShow(true);
                setLoading(false);
                handleClose();
            });
    }

    const handleTableChange = (filters, page, sizePerPage, actionType) => {
        pagination.pageSelected = page;
        pagination.sizePerPage = sizePerPage;
        pagination.filterMOs = filters;
        retreiveSubscriptions(pagination, actionType);
    }

   

    const [showButton, setShowButton] = useState(true);
    const [showMessage, setShowMessage] = useState(false);
    const nodeRef = useRef(null);

    return (
        <div className="col-md-10">
            <div className="col-md-10 float-right">
                <div className="row"><div className="float-left col-md-2 p-0"><h4>Subscriptions</h4></div>{showButton && (
                    <div className="float-left col-md-9">  <h5 className="mt-1">  <Link className="addNewHeading"
                        onClick={() => setShowMessage(true)}
                    >
                        Add Subscription
                    </Link></h5></div>
                )
                }</div>
                {show && (
                    <Alert className="col-md-6" variant={alertVariant} onClose={() => setShow(false)} dismissible>
                        <Alert.Heading>{alertHeading}</Alert.Heading>
                        <p>{message}</p>
                    </Alert>

                )}
                <div className="row">
                    <CSSTransition
                        in={showMessage}
                        nodeRef={nodeRef}
                        timeout={500}
                        classNames="newBox"
                        unmountOnExit
                        onEnter={() => setShowButton(false)}
                        onExited={() => setShowButton(true)}
                    >
                        <div className="addNewBox p-0"
                            ref={nodeRef}
                        ><div className="col-md-12 p-0">
                                <div className="float-left col-md-10"><h5 style={{ marginLeft: '35px', marginTop: '5px' }}>Add Subscription</h5> </div>
                                <div className="col-md-2 float-right">
                                    <button className="float-right addNewClose"
                                        onClick={() => setShowMessage(false)}
                                    >
                                        X
                                    </button>
                                </div>
                            </div>
                            <div className="p-0  ml-0" style={{ height: 'auto' }}>
                                <AddSubscription updateParent={updateParent} />
                            </div>
                        </div>
                    </CSSTransition>
                </div>
                <div className="row mt-3">
                    <Loader openDialog={open}></Loader>
                    <FMTable columns={columns} dataList={subscriptions} pageMO={pageMO} handlePagination={handleTableChange} expandRow={expandRow} showFilter={true} />
                </div>
            </div>
        </div >
    )
}
export default SubscriptionList;