import React, { useState, useRef } from "react";
import '../../login.css';
import { useNavigate } from 'react-router-dom';
import UserService from '../service/UserService'
import Model from "../common/editableTable/Model";
import PasswordBox from "../common/PasswordBox";

const Login = (props) => {
    const [submitted, setSubmitted] = useState(false);
    /**model attributes*/
    const [message, setMessage] = useState("");
    const [modelVariant, setModelVariant] = useState();
    const autoRef = useRef(null);
    /**model attributes*/
    const navigate = useNavigate();
    const userMO = {
        userName: '',
        password: ''
    }

    const [appUser, setAppUser] = useState(userMO);

    const error = {
        userNameError: '',
        passwordError: ''
    }
    const [appUserError, setAppUserError] = useState(error);

    const handleInputChange = event => {
        console.log(event);
        const { name, value } = event.target;
        setAppUser({ ...appUser, [name]: value });
    };

    const handleValidation = () => {
        if (!appUser?.userName) {
            setAppUserError({ ...appUserError, "userNameError": "please enter user name" });
            return false;
        } else if (!appUser?.password) {
            setAppUserError({ ...appUserError, "userNameError": '', "passwordError": "please enter password" });
            return false;
        }
        setAppUserError({ ...appUserError, "userNameError": '', "passwordError": "" });
        return true;
    }

    const verifyLogin = () => {
        if (handleValidation()) {
            //remove user incase if user closes the browser without logging out
            setSubmitted(true);
            UserService.verifyLogin(appUser).
                then(response => {
                    setSubmitted(false);
                    navigate("/anchor/productUser")
                }).catch(e => {
                    setSubmitted(false);
                    setModelVariant('error')
                    setMessage(e.response.data.message);
                    autoRef.current.setModelOpen(true);
                })
        }
    }

    const style = {
        marginLeft: '10%',
        marginTop: '0%'
    }

    return (
        <div className='homepage col-md-12 '>

            <div className='float-left col-md-6' >

            </div>
            <div className='login'>
                <div className="loginHeader"><h2>Login</h2></div>
                <Model style={style} message={message} ref={autoRef} hideDuration={6000} variant={modelVariant}></Model>
                <div className="loginComp">
                    <label> User Name <span className='errorMsg'>  {appUserError.userNameError}</span></label>
                    <input type='text' autocomplete='off' name='userName' id='userName' onChange={(e) => handleInputChange(e)}></input>
                </div>
                <div className="loginComp">
                    <label> Password <span className='errorMsg'>  {appUserError.passwordError}</span></label>
                    <PasswordBox name='password' id='password' onChange={(e) => handleInputChange(e)} />
                </div>
                <div className="loginComp">
                    <button name='signinBtn' id='signinBtn' onClick={(e) => verifyLogin()} disabled={submitted}>
                        {submitted ? 'Signing in...' : 'Sign In'}</button>
                </div>
            </div>
        </div>
    )

}
export default Login;