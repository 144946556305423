
//url 

const LOGIN_URL =  '/login';
const ERROR_PAGE =  '/errorPage';
const ERROR_MSG = 'Error while retrieving';
export default {
    LOGIN_URL,
    ERROR_PAGE,
    ERROR_MSG
};