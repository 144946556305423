import { Alert, Button } from "react-bootstrap";
import { useState } from "react";
import ManageProductService from "../service/ManageProductService";
import NumberInput from "../common/NumberInput";

const EditPlan = ({ data, updateParent }) => {
    const [message, setMessage] = useState("");
    const [alertVariant, setAlertVariant] = useState("");
    const [alertHeading, setAlertHeading] = useState("");
    const [show, setShow] = useState(false);
    const [plan, setPlan] = useState(data);
    const [updateSubmitted, setUpdateSubmitted] = useState(false);
    const [deleteSubmitted, setDeleteSubmitted] = useState(false);

    const [planError, setPlanError] = useState({
        typeError: '',
        validityError: '',
        priceError : ''
    });

    const handleInputChange = event => {
        const { name, value } = event.target;
        setPlan({ ...plan, [name]: value });
    };

    const handleInputChangeRaw = (value, name) => {
        setPlan({ ...plan, [name]: value });
    };

    const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

    const handleValidation = () => {
        if (!plan.type) {
            setPlanError({ ...planError, 'typeError': 'Please enter plan type' });
            return false;
        } else if (!plan.validity) {
            setPlanError({ ...planError, 'typeError': '', 'validityError': 'Please enter validity' });
            return false;
        }
        else if (!plan.price) {
            setPlanError({ ...planError, 'typeError': '', 'validityError': '', 'priceError': 'Please enter price' });
            return false;
        }
        setPlanError({ ...planError, 'typeError': '', 'validityError': '', 'priceError': '' });
        setPlanError({ ...planError, 'typeError': '', 'validityError': '' });
        return true;
    }

    const updatePlan = () => {
        setUpdateSubmitted(true);
        if (handleValidation()) {
            ManageProductService.updatePlan(plan)
                .then(response => {
                    updateParent()
                    setShow(true);
                    setTimeout(() => {
                        setShow(false);
                    }, 3000);
                    setAlertHeading("Success!!");
                    setAlertVariant("success");
                    setMessage("Successfully updated");
                    setUpdateSubmitted(false);
                })
                .catch(e => {
                    setShow(true);
                    setMessage(e.response.data);
                    setAlertHeading("Error!");
                    setAlertVariant("danger");
                    setUpdateSubmitted(false);
                })
        }
        setUpdateSubmitted(false);
    }

    const deletePlan = () => {
        setDeleteSubmitted(true);
        ManageProductService.deletePlan(plan)
            .then(response => {
                console.log(response.data);
                updateParent()
            })
            .catch(e => {
                setMessage(e.response.data);
                setAlertHeading("Error!");
                setAlertVariant("danger");
                setDeleteSubmitted(false);
                setShow(true);
            });
    };


    return (
        <div className="p-5 pt-0">
            <div className="col-md-12 float-right">
                {show &&
                    <div className="col-md-12 float-left">
                        <Alert style={{ width: 'auto' }} variant={alertVariant} onClose={() => setShow(false)} dismissible>
                            <Alert.Heading>{alertHeading}</Alert.Heading>
                            <p>{message}</p>
                        </Alert></div>}
                <div className="adminFormLeft">
                    <lable className='required'>Plan Type</lable> <span className='errorMsg'>  {planError.typeError}</span>
                    <input className="form-control" type="text" value={plan.type} onChange={(e) => handleInputChange(e)}
                        id="type"
                        name="type"
                    ></input>
                </div>

                <div className="adminFormRight">
                    <lable className='required'>Validity</lable> <span className='errorMsg'>  {planError.validityError}</span>
                    <NumberInput autoComplete="off" id="validity" name="validity" isDecScaleEnabled={true} decimalScale={0}
                        value={plan.validity} className="form-control"
                        onChange={(value) => handleInputChangeRaw(value, "validity")}></NumberInput>
                </div>
                <div className="adminFormLeft mt-2">
                    <lable className='required'>Price</lable> <span className='errorMsg'>  {planError.priceError}</span>
                    <NumberInput autoComplete="off" id="price" name="price" isDecScaleEnabled={true} decimalScale={0}
                        value={plan.price} className="form-control"
                        onChange={(value) => handleInputChangeRaw(value, "price")}></NumberInput>
                </div>

                <div className="col-md-12 float-left mt-3" style={{ paddingBottom: '10px' }}>
                    <div className="float-right">  <button
                        className="update-btn float-right"
                        variant="contained" type="button"
                        onClick={(e) => updatePlan()} disabled={updateSubmitted}
                    >{updateSubmitted ? 'Updating....' : 'Update'}
                    </button>
                    </div>
                    <div className="float-right" style={{ marginRight: '8px' }}>
                        <button
                            className="delete-btn float-right"
                            variant="contained"
                            disabled={deleteSubmitted}
                            type="button"
                            onClick={(e) => deletePlan()}
                        >
                            {deleteSubmitted ? 'Deleting...' : 'Delete'}
                        </button>
                    </div>
                </div>


            </div>
        </div>
    )
}

export default EditPlan;