import './App.css';
import './table.css';
import { BrowserRouter as AppRouter, Routes, Route, Outlet } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Navbar, Nav, NavDropdown, NavItem } from 'react-bootstrap';
import "react-datepicker/dist/react-datepicker.css";
import Logout from './components/access/Logout';
import SuperAdmin from './components/access/SuperAdmin';

function AnchorApp() {
  return (

    <div>
      <Navbar className='navbar' variant="dark" expand="lg" sticky="top">
        <Navbar.Brand href="#home" className='p-0' style={{ marginLeft: '20px' }}> Fuelman - Anchor </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" style={{ marginLeft: '20px' }} />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto" style={{ marginLeft: '250px' }}>
            <NavDropdown title="Manage Product User" id="basic-nav-dropdown">
              <NavDropdown.Item href="/anchor/productUser">Product User</NavDropdown.Item>
              <NavDropdown.Item href="/anchor/subscription">Subscription</NavDropdown.Item>
              <NavDropdown.Item href="/anchor/plan">Plan</NavDropdown.Item>
            </NavDropdown>
          </Nav>
          
          <div className='custNavText'><SuperAdmin/></div>
          <div className='custNavText'><Logout/></div>
        </Navbar.Collapse>
      </Navbar>
      <div className="container mt-3">
        <Outlet />
      </div>
    </div>
  )
}

export default AnchorApp;
