import { Alert, Button } from "react-bootstrap";
import { useState } from "react";
import ManageProductService from "../service/ManageProductService";
import NumberInput from "../common/NumberInput";

const EditProductUser = ({ data, updateParent }) => {
    const [message, setMessage] = useState("");
    const [alertVariant, setAlertVariant] = useState("");
    const [alertHeading, setAlertHeading] = useState("");
    const [show, setShow] = useState(false);
    const [productUser, setProductUser] = useState(data);
    const [updateSubmitted, setUpdateSubmitted] = useState(false);
    const [deleteSubmitted, setDeleteSubmitted] = useState(false);

    const [productUserError, setProductUserError] = useState({
        nameError: '',
        emailIdError: '',
        mobileNoError: ''
    });

    const handleInputChange = event => {
        const { name, value } = event.target;
        setProductUser({ ...productUser, [name]: value });
    };

    const handleInputChangeRaw = (value, name) => {
        setProductUser({ ...productUser, [name]: value });
    };

    const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

    const handleValidation = () => {
        if (!productUser.name) {
            setProductUserError({ ...productUserError, 'nameError': 'Please enter product user name' });
            return false;
        } else if (!productUser.emailId) {
            setProductUserError({ ...productUserError, 'nameError': '', 'emailIdError': 'Please enter email id' });
            return false;
        } else if (!productUser.emailId.match(isValidEmail)) {
            setProductUserError({ ...productUserError, 'nameError': '', 'emailIdError': "'" + productUser.emailId + "' is not a valid email address" });
            return false;
        } else if (!productUser.mobileNo) {
            setProductUserError({ ...productUserError, 'nameError': '', 'emailIdError': '', 'mobileNoError': 'Please enter mobile number' });
            return false;
        }
        setProductUserError({ ...productUserError, 'nameError': '', 'emailIdError': '', 'mobileNoError': '' });
        return true;
    }

    const updateProductUser = () => {
        setUpdateSubmitted(true);
        if (handleValidation()) {
            ManageProductService.updateProductUser(productUser)
                .then(response => {
                    updateParent()
                    setShow(true);
                    setTimeout(() => {
                        setShow(false);
                    }, 3000);
                    setAlertHeading("Success!!");
                    setAlertVariant("success");
                    setMessage("Successfully updated");
                    setUpdateSubmitted(false);
                })
                .catch(e => {
                    setShow(true);
                    setMessage(e.response.data);
                    setAlertHeading("Error!");
                    setAlertVariant("danger");
                    setUpdateSubmitted(false);
                })
        }
        setUpdateSubmitted(false);
    }

    const deleteProductUser = () => {
        setDeleteSubmitted(true);
        ManageProductService.deleteProductUser(productUser)
            .then(response => {
                console.log(response.data);
                updateParent()
            })
            .catch(e => {
                setMessage(e.response.data.message);
                setAlertHeading("Error!");
                setAlertVariant("danger");
                setDeleteSubmitted(false);
                setShow(true);
            });
    };


    return (
        <div className="p-5 pt-0">
            <div className="col-md-12 float-right">
                {show &&
                    <div className="col-md-12 float-left">
                        <Alert style={{ width: 'auto' }} variant={alertVariant} onClose={() => setShow(false)} dismissible>
                            <Alert.Heading>{alertHeading}</Alert.Heading>
                            <p>{message}</p>
                        </Alert></div>}
                <div className="adminFormLeft">
                    <lable className='required'>Product User Name</lable> <span className='errorMsg'>  {productUserError.nameError}</span>
                    <input className="form-control mt-2" type="text" value={productUser.name} onChange={(e) => handleInputChange(e)}
                        id="name"
                        name="name"
                    ></input>
                </div>

                <div className="adminFormRight">
                    <lable className='required'>Email Id</lable> <span className='errorMsg'>  {productUserError.emailIdError}</span>
                    <input className="form-control  mt-2" type="text" value={productUser.emailId} onChange={(e) => handleInputChange(e)}
                        id="emailId"
                        name="emailId"
                    ></input>
                </div>

                <div className="adminFormLeft mt-2">
                    <lable className='required'>Mobile No</lable><span className='errorMsg'>  {productUserError.mobileNoError}</span>
                    <NumberInput autoComplete="off" id="mobileNo" name="mobileNo" isDecScaleEnabled={true} decimalScale={0}
                        value={productUser.mobileNo} className="form-control"
                        onChange={(value) => handleInputChangeRaw(value, "mobileNo")}></NumberInput>
                </div>

                <div className="adminFormRight mt-2">
                    <lable>Unit</lable>
                    <input className="form-control  mt-2" type="text" id="unit"
                        name="unit" onChange={(e) => handleInputChange(e)}
                        value={productUser.unit}
                    ></input>
                </div>

                <div className="col-md-12 float-left mt-3" style={{ paddingBottom: '10px' }}>
                    <div className="float-right">  <button
                        className="update-btn float-right"
                        variant="contained" type="button"
                        onClick={(e) => updateProductUser()} disabled={updateSubmitted}
                    >{updateSubmitted ? 'Updating....' : 'Update'}
                    </button>
                    </div>

                    {/* delete button is not required for product user
                       <div className="float-right" style={{marginRight: '8px'}}>
                            <button
                                className="delete-btn float-right"
                                variant="contained"
                                disabled={deleteSubmitted}
                                type="button"
                                onClick={(e) => deleteProductUser()}
                            >
                                {deleteSubmitted ? 'Deleting...' : 'Delete'}
                            </button>
                        </div> */}
                </div>


            </div>
        </div>
    )
}

export default EditProductUser;