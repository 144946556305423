import * as React from 'react';
import { useState, useEffect } from "react";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, StyledEngineProvider } from '@mui/material';

export default function AlertDialog({ openDialog, dialogShowStateVar, dialogTitle, message, yesBtnHandler, noBtnHandler }) {

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
    dialogShowStateVar(false);
    if (!!noBtnHandler?.callAction) {
      return noBtnHandler.callAction()
    }
  };

  useEffect(() => {
    setOpen(openDialog);
  }, [openDialog]);

  const handleOk = () => {
    setOpen(false);
    if (!!yesBtnHandler?.callAction) {
      return yesBtnHandler.callAction()
    }
  };



  const [buttonFocus, setButtonFocus] = useState({ backgroundColor: 'grey' });
  const setOnFocus = () => {
    setButtonFocus({ backgroundColor: 'yellow' });
  }

  return (
    <div>
      <StyledEngineProvider injectFirst>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle style={{ color: 'black' }} id="alert-dialog-title">
            {dialogTitle}
          </DialogTitle>
          <DialogContent>
            <DialogContentText className='errorMsg warn-content' id="alert-dialog-description">
              {message}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <button  onFocus={setOnFocus} className='alertYes alertDialogBtn'
              onClick={handleOk}>{yesBtnHandler?.name}</button>
            <button  className='alertNo alertDialogBtn' onClick={handleClose}>
              {noBtnHandler?.name}
            </button>
          </DialogActions>
        </Dialog>
      </StyledEngineProvider>
    </div>
  );
}
